// registration form
export const genderOptions = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
];

export const typeOfRegistrationOptions = {
  player: [
    {
      value: "player",
      label: "Player",
    },
  ],
  volunteer: [
    {
      value: "volunteer",
      label: "Volunteer",
    },
    {
      value: "trainer",
      label: "Trainer",
    },
    {
      value: "coach",
      label: "Coach",
    },
  ],
  referee: [
    {
      value: "referee",
      label: "Referee",
    },
  ],
};

export const memberTypes = [
  {
    name: "Player Tackle",
    type: "player",
    playing: true,
    stateType: "club-league",
  },
  {
    name: "Player LeagueTag",
    type: "player-league-tag",
    playing: true,
    stateType: "club-league",
  },
  {
    name: "Player League Touch",
    type: "player-league-touch",
    playing: true,
    stateType: "club-league",
  },
  {
    name: "Player (Touch)",
    type: "player-touch",
    playing: true,
    stateType: "unknown",
  },
  {
    name: "Player Representative",
    type: "player-representative",
    playing: true,
    stateType: "club-league",
  },
  {
    name: "Player Masters",
    type: "player-masters",
    playing: true,
    stateType: "club-league",
  },
  {
    name: "Player Social",
    type: "player-social",
    playing: true,
    stateType: "club-league",
  },
  {
    name: "Player School",
    type: "player-school",
    playing: true,
    stateType: "club-league",
  },
  {
    name: "Non-Participating Member",
    type: "non-participating-member",
    playing: false,
    stateType: "both",
  },
  {
    name: "Referee",
    type: "referee",
    playing: false,
    stateType: "referee",
  },
  {
    name: "Coach",
    type: "coach",
    playing: false,
    stateType: "both",
  },
  {
    name: "Volunteer",
    type: "volunteer",
    playing: false,
    stateType: "both",
  },
  {
    name: "Trainer",
    type: "trainer",
    playing: false,
    stateType: "club-league",
  },
];

// Club Search
export const lengthChoices = [
  {
    value: "1 day",
    label: "1 day",
  },
  {
    value: "1 week",
    label: "1 week",
  },
  {
    value: "1 week +",
    label: "1 week +",
  },
];

// Club Search Alternative
export const sessionChoices = [
  {
    value: "1 session",
    label: "1 session",
  },
  {
    value: "2 session",
    label: "2 session",
  },
  {
    value: "6 session",
    label: "6 session",
  },
  {
    value: "8 session",
    label: "8 session",
  },
];

export const daysChoices = [
  {
    value: "Monday",
    label: "Monday",
  },
  {
    value: "Tuesday",
    label: "Tuesday",
  },
  {
    value: "Wednesday",
    label: "Wednesday",
  },
  {
    value: "Thursday",
    label: "Thursday",
  },
  {
    value: "Friday",
    label: "Friday",
  },
  {
    value: "Saturday",
    label: "Saturday",
  },
  {
    value: "Sunday",
    label: "Sunday",
  },
];

// filters of the checkboxes
export const memberDisplayType = ["Player", "Referee", "Coach", "Volunteer", "Trainer"];

export const activity = [
  "Club League",
  "League Stars",
  "Munchkin League",
  "Holiday Clinic",
  "Social",
  "Masters",
  "Rise",
];

export const gender = ["Male", "Female"];

export const competitionType = ["Contact", "Non-contact", "Touch"];

// age filter - checkboxes
export const ageLevels = [
  { name: "5 and under", min: 2, max: 5 },
  { name: "6-9 Yrs", min: 6, max: 9 },
  { name: "10-12 Yrs", min: 10, max: 12 },
  { name: "13-16 Yrs", min: 13, max: 16 },
  { name: "17-18 Yrs", min: 17, max: 18 },
  { name: "19+", min: 19, max: 99 },
];

export const idTypes = [
  {
    type: "birth-certificate",
    name: "Birth Certificate",
  },
  {
    type: "drivers-licence",
    name: "Driver's Licence",
  },
  {
    type: "passport",
    name: "Passport",
  },
  {
    type: "photo-id",
    name: "Photo ID",
  },
  {
    type: "other",
    name: "Other",
  },
];

export const docUploadURL = `${process.env.VUE_APP_API_URL}/nrl/api/v1/portal/members/upload-verification-document`;
export const headshotUploadURL = `${process.env.VUE_APP_API_URL}/nrl/api/v1/portal/members/upload-headshot`;
export const countries = [
  "Australia",
  "New Zealand",
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote D'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "England",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic Of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "RWANDA",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Scotland",
  "Senegal",
  "Serbia and Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Surilabel",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wales",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
].map((x) => ({ label: x, value: x }));
// countries includes `Prefer not to say` option
export const countriesWithPNTSOption = countries.concat({
  label: "Prefer not to say",
  value: "Prefer not to say",
});

export const stateTeams = ["New South Wales Blues", "Queensland Maroons", "Prefer not to say"].map(
  (x) => ({ label: x, value: x })
);

export const nationalMensTeams = [
  "Broncos",
  "Bulldogs",
  "Cowboys",
  "Dolphins",
  "Dragons",
  "Eels",
  "Knights",
  "Panthers",
  "Rabbitohs",
  "Raiders",
  "Roosters",
  "Sea Eagles",
  "Sharks",
  "Storm",
  "Titans",
  "Warriors",
  "Wests Tigers",
  "Prefer not to say",
].map((x) => ({ label: x, value: x }));

export const nationalWomensTeams = [
  "Broncos",
  "Cowboys",
  "Dragons",
  "Eels",
  "Knights",
  "Raiders",
  "Roosters",
  "Sharks",
  "Titans",
  "Wests Tigers",
  "Prefer not to say",
].map((x) => ({ label: x, value: x }));

export const schoolGrades = [
  "Prep",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
].map((x) => ({ label: x, value: x }));

export const typesOfDispensations = [
  "18 Month Registration Window",
  "Weight for Age",
  "Over 40 Clearance",
  "Medical Clearance",
  "Play above (1 )Age Group consent",
  "Play above (2) Age Groups consent",
  "Play below (1 )Age Group consent",
  "Play below (2) Age Groups consent",
].map((x) => ({ label: x, value: x }));

export const reasons18M = [
  "Calendar group not available",
  "Differences in maturity relative to age group",
  "Feeling overly challenged in their calendar group",
  "New to the game",
  "Other",
].map((x) => ({ label: x, value: x }));

export const coachingAgeGroups = [
  "Under 6",
  "07 years",
  "08 years",
  "09 years",
  "10 years",
  "11 years",
  "12 years",
  "13 years",
  "14 years",
  "15 years",
  "16 years",
  "17 years",
  "18 years",
  "Seniors (19+)",
].map((x) => ({ label: x, value: x }));

export const sportsTrainerTypes = [
  "Community - LeagueSafe",
  "Community - League First Aid",
  "Community - Sports Trainer",
  "Performance - Assistant Medical Trainer",
  "Performance - Head Trainer",
  "Performance - Message Runner",
].map((x) => ({ label: x, value: x }));

export const refereeTypes = [
  "Association Member",
  "Coaching Match Official",
  "Match Official",
  "Match Official/Coaching Match Official",
].map((x) => ({ label: x, value: x }));

export const volunteerPrimaryRoles = [
  "Committee Member",
  "Food/Beverage Sales",
  "Ground Manager",
  "President",
  "Registrar",
  "Secretary",
  "Team Manager",
  "Treasurer",
  "Website/Social Media Editor",
].map((x) => ({ label: x, value: x }));

export const volunteerPrimaryRolesTfa = [
  "Committee Member",
  "Food/Beverage Sales",
  "Ground Manager",
  "President",
  "Registrar",
  "Secretary",
  "Team Manager",
  "Treasurer",
  "Website/Social Media Editor",
  "Medical",
  "Tour leaders",
  "General volunteer",
].map((x) => ({ label: x, value: x }));

export const ethnicityOptions = [
  "Australian",
  "Aboriginal",
  "New Zealander",
  "Maori",
  "Samoan",
  "Tongan",
  "Fijian",
  "Papua New Guinean",
  "Cook Islander",
  "Torres Strait Islander",
  "Aboriginal/Torres Strait Islander",
  "None of the above",
].map((x) => ({ label: x, value: x }));

export const states = [
  "New South Wales",
  "Queensland",
  "South Australia",
  "Tasmania",
  "Victoria",
  "Western Australia",
  "Australian Capital Territory",
  "Northern Territory",
].map((x) => ({ label: x, value: x }));

export const nationalOrgs = {
  "rugby-league": {
    code: "rugby-league",
    name: "National Rugby League",
  },
  "touch-football": {
    code: "touch-football",
    name: "Touch Football Association",
  },
};

export const mastersTeams = [
  "ACT Valley Dragons Masters",
  "Aspley Devils RLFC",
  "BALLINA SEAGULLS",
  "Beaudesert Kingfishers",
  "Beers",
  "Blacktown Masters",
  "Booval Swifts Old Boys",
  "Botany Rams",
  "Bowen Basin Barbarians",
  "Brahmans old bulls",
  "Bribie Warrigals Old Boys",
  "Brissyraces.com.au",
  "Broken Hill United Oldboys",
  "Brothers Bushrangers",
  "Bruddaz United 1",
  "Bruddaz United 2",
  "BURLEIGH Juniors",
  "Butchers Old Dogs",
  "Canterbury Kiwis & Co",
  "Cohoe 1",
  "Cohoe roosters 2",
  "Coogee Dolphins Old Boys",
  "Cook Islands Masters",
  "Country Crustaceans",
  "Dalby Diehard Legends",
  "Darwin Salties",
  "Dayboro Cowboys Old Boys",
  "De La Salle 97 Old Boys",
  "Doonnside Rebels",
  "Doonside dirty rats",
  "Dysart Bulls",
  "FNQ Cyclones",
  "Forster Tuncurry Hawks",
  "Gayndah Gladiators",
  "Gerringong Lions Blue bags",
  "Gladstone Brothers Old Boys",
  "Gold Coast Wanderers",
  "Grenfell Dinosaurs",
  "Guyra Crusty Spuds",
  "Harbord Hazbeens",
  "Hearty Lions",
  "Helensburgh Tigers Old Boys",
  "Herbert River Old Boys",
  "Hibiscus Coast Raiders",
  "Ipswich Brothers",
  "Ipswich Rock Wallabies",
  "Jake's Allstars",
  "JAMBEROO OLDEROOS",
  "JAMBEROO SUPEROOS (W)",
  "Jimboomba Thunder",
  "Jugglers Lions",
  "KIA TOA UNITED",
  "Kingaroy Red ant old boys",
  "Kingfishers Masters",
  "Lionsden",
  "London Colonials",
  "Lyndhurst blue heelers",
  "Macquarie United Old Boys",
  "Mallabula Panthers",
  "Maroochydore swans",
  "Melbourne Thunder Masters",
  "Middlemount Panthers",
  "Millmerran Rams",
  "Moss Frail Old Boys",
  "Muswellbrook Sharks",
  "Nelson Bay Blues",
  "Noosa Cutters",
  "Noosa Pirates",
  "Norfolk Mutineers",
  "North Queensland Murri Master",
  "Norths tigers",
  "Old Man Rivers",
  "Orange Ladbrokes Gladys",
  "Picton Magpies",
  "Preston Peacocks",
  "QLD Police Old Dawgs",
  "Queanbeyan Blues Masters",
  "Queensland Redbacks",
  "Raymond Terrace Magpies",
  "Richmond Roses Agelu's",
  "Richmond Young Dogz",
  "Rocky Brothers Old Boys",
  "Runaway Bay - Bay Boys Masters",
  "Runaway Bay - Bay Girls",
  "Runaway Bay Old Boys ( Old dogs)",
  "Runaway Bay Old Boys (crustaceans)",
  "SA Crow-Knees",
  "Sarina Old Crocs",
  "Sawtell Rusty Panthers",
  "Scone wombats",
  "The Silverbacks",
  "South QLD Maori RL Matua",
  "South Tuggeranong Masters",
  "Southern Black Snakes",
  "Southside Pirates",
  "Springsure Mountain Men Oldies",
  "St George Beer Bellies",
  "St Marys Golden Oldies",
  "St.Doms Old Boy's",
  "Sunshine Coast Old Buzzards",
  "Tannum Sands Old Boys",
  "Tigers old boys mackay",
  "Townsville Pirates",
  "Tuggeranong Buffaloes Masters",
  "Uni of Newcastle Sea Slugs",
  "West Belconnen Ugly Warriors",
  "West Inala",
  "Western Rams",
  "Wests Panthers",
  "Wickham Wasps",
  "Windale eagles",
  "Woolgoola Razorbacks",
  "Yass Greypies",
  "YCW Rangers",
  "Yeppoon Seagulls Oldboys",
  "YGCC - Young Gunners",
  "Young Cherryatrics",
];

export const occupationOptionsTfa = [
  "Administration & General Management",
  "Community or Social Services",
  "Dietitian or Nutritionist",
  "Doctor (Medical)",
  "Finance",
  "Hospitality or Tourism",
  "Human Resources or Recruitment",
  "IT",
  "Lawyer",
  "Marketing/Communications/Media",
  "Personal Trainer or Fitness",
  "Physiotherapist or similar",
  "Public Service",
  "Real Estate or Property",
  "Retired",
  "Sales, Retail or Customer Service",
  "Stay-at-Home Parent",
  "Student",
  "Teacher",
  "Trades/Construction",
  "Not currently working",
  "Other",
].map((x) => ({ label: x, value: x }));

export const interestedInOptionsTfa = [
  "Returning Participant",
  "TFA Website",
  "Play Rugby League Website",
  "Other Website",
  "Word of mouth",
  "Social Media",
  "Promotional Flyers",
  "News Media",
  "NRL Touch Premiership",
  "Radio Ad",
  "School",
  "Other",
].map((x) => ({ label: x, value: x }));

export const disabilityTypes = [
  "Vision Impairment",
  "Hearing Impairment",
  "Intellectual disability",
  "Autism",
  "Asperger's Syndrome",
  "Cerebral Palsy",
  "Spina Bifida",
  "Spinal Cord Injury",
  "Amputation",
  "Other",
].map((x) => ({ label: x, value: x }));
